<template>
    <div class="userinfo">
       <h3>个人信息</h3>
       <a-form :model="formState" layout="vertical" :label-col="labelCol" :wrapper-col="wrapperCol">
        <!-- <a-form-item label="头像" class="item">
        <a-input v-model:value="formState.name" />
            
                <p>
                    <img :src="formState.avatar" alt="">
                    <span @click="emdit">修改</span>
                </p>
        </a-form-item> -->
        <a-form-item label="昵称" class="item">
        <!-- <a-input v-model:value="formState.name" /> -->      
            <p>
                    <span>{{ formState.nickname }}</span>
                    <span></span>
            </p>
                <!-- <a-modal 
                v-model:visible="visible" 
                title="修改密码"  
                centered 
                ok-text="确认"
                cancel-text="取消"
                @ok="handleOk">
                    <a-form :model="form">
                        <a-form-item >
                            <a-input placeholder="6-8位字母加数字"/>
                        </a-form-item>
                        <a-form-item  name="password">
                            <a-input placeholder="确认新密码"/>
                        </a-form-item>
                       
                    </a-form>
                </a-modal> -->
        </a-form-item>
        <a-form-item label="性别" class="item">
        <!-- <a-input v-model:value="formState.name" /> -->
            
                <p>
                    <span v-if="formState.sex == 1 ? sex = '男':sex = '女'">{{ sex }}</span>
                    <!-- <span @click="emdit">修改</span> -->
                    <!-- <a-select v-model:value="formState.region" placeholder="please select your zone">
                        <a-select-option value="shanghai">Zone one</a-select-option>
                        <a-select-option value="beijing">Zone two</a-select-option>
                    </a-select> -->
                </p>
        </a-form-item>
        <!-- <a-form-item label="生日" class="item">
        
                <p>
                    <span >{{ formState.birthday }}</span>
                    <span @click="emdit">修改</span>
                </p>
        </a-form-item> -->
        <a-form-item label="地区" class="item">
        <!-- <a-input v-model:value="formState.name" /> -->
            
                <p>
                    <span v-if="formState.province ? province = formState.province: province = '-'">{{ province }}</span>
                    <!-- <span @click="emdit">修改</span> -->
                </p>
        </a-form-item>
    </a-form>

    </div>
</template>

<script setup>
import { getUserInfoApi } from "@/api/userinfo";
import { es } from "element-plus/es/locale";
import { onMounted, reactive, ref } from "vue";

const formState = reactive({
    nickname:'',
    phone:'',
    avatar:'',
    province:'',
    sex:'',
    birthday:''
})

const visible = ref(false)
const showModal = () => {
      visible.value = true;
};

const getinfo = async()=>{
    let res = await getUserInfoApi()
    if(res.code==200){
        console.log(res);
        formState.nickname = res.data.nickname
        formState.phone = res.data.phone
        formState.avatar = res.data.avatar
        formState.sex = res.data.sex
        formState.province = res.data.province
        formState.birthday = res.data.birthday
    }
}

onMounted(() => {
    getinfo()
})


</script>

<style lang="less" scoped>
.userinfo{
    height: 500px;
    background-color: #fff;
    padding: 10px;
    h3{
        color: #d90d0d;
    }
    :deep(.item){
            height: 100px;
            background-color: #e9e7e7;
            // font-size: 30px;
            padding: 5px;
            margin-top: 20px;
            p{
                background-color: #fff;
                height: 60px;
                line-height: 60px;
                display: flex;
                padding-left: 5px;
                padding-right: 10px;
                justify-content: space-between;
                span{
                    display: inline-block;
                    // &:nth-last-child(1){
                    //     &:hover{
                    //         color: #d90d0d;
                    //         cursor: pointer;
                    //     }
                    // }
                }
            }
        }
}
</style>